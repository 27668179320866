<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Accredited Institution Programmes
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-5 lg:col-4">
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText
                  outlined
                  dense
                  placeholder="Search"
                  class="w-full"
                  v-model="searchInput"
                />
              </span>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="flex align-items-center justify-content-around">
              <div
                v-if="searchText"
                class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente"
              >
                Search
                <Chip
                  class="font-medium px-2 py-1"
                  removable
                  @remove="clearSearch()"
                  >{{ searchText }}</Chip
                >
              </div>
            </div>
            <div class="">
              <div>
                <template v-if="showBreadcrumbs && $route.query.tag">
                  <Breadcrumb
                    :home="{
                      icon: 'pi pi-home',
                      to: '/accredited_institution_programmes',
                    }"
                    :model="pageBreadCrumb"
                  />
                </template>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records">
                      <DataTable
                        :lazy="true"
                        :loading="loading"
                        :value="records"
                        dataKey="caps_institution_id"
                        @sort="onSort($event)"
                        class=""
                        :showGridlines="false"
                        :rowHover="true"
                        responsiveLayout="stack"
                      >
                        <Column
                          field="institution_name"
                          header="Institution Name"
                        >
                          <template #body="{ data }">
                            {{ data.institution_name }}
                          </template>
                        </Column>
                        <Column
                          field="programme_title"
                          header="Programme Title"
                        >
                          <template #body="{ data }">
                            {{ data.programme_title }} {{ data.programme_name }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div
                        v-show="loading"
                        class="flex align-items-center justify-content-center text-gray-500"
                      >
                        <div>
                          <ProgressSpinner style="width: 30px; height: 30px" />
                        </div>
                      </div>
                    </template>
                    <!-- end of page loading indicator-->
                    <!-- Empty record -->
                    <template v-if="!loading && !records.length">
                      <div
                        class="p-3 my-3 text-500 text-lg font-medium text-center"
                      >
                        No record found
                      </div>
                    </template>
                    <!-- end of empty record-->
                    <!-- pagination component-->
                    <template v-if="showFooter">
                      <div class="">
                        <div v-show="!loading">
                          <div class="flex justify-content-between">
                            <div
                              class="flex justify-content-center flex-grow-0"
                            ></div>
                            <div
                              v-if="paginate && totalPages > 1"
                              class="flex-grow-1 my-1"
                            >
                              <Paginator
                                @page="
                                  (event) => {
                                    pagination.page = event.page + 1;
                                  }
                                "
                                :rows="pagination.rowsPerPage"
                                :totalRecords="totalRecords"
                              >
                                <template #start>
                                  <span class="text-sm text-gray-500 px-2">
                                    Records
                                    <b
                                      >{{ recordsPosition }} of
                                      {{ totalRecords }}</b
                                    >
                                  </span>
                                </template>
                                <template #end>
                                  <Dropdown v-model="pagination.rowsPerPage"
                                    :options="[5, 10, 15, 20, 30, 40, 50, 100, 200]">
                                  </Dropdown>  
                                </template>
                              </Paginator>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- end of pagination component-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { ListPageMixin } from "../../mixins/listpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  name: "listAccreditedinstitutionprogrammesPage",
  components: {},
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: "",
    },
    pageName: {
      type: String,
      default: "accredited_institution_programmes",
    },
    routeName: {
      type: String,
      default: "accredited_institution_programmeslist",
    },
    apiPath: {
      type: String,
      default: "accredited_institution_programmes/index",
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    multiCheckbox: {
      type: Boolean,
      default: false,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to delete this record?",
    },
  },
  data() {
    return {};
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "Accredited Institution Programmes";
      },
    },
    records: {
      get: function () {
        return this.$store.getters["accredited_institution_programmes/records"];
      },
      set: function (value) {
        this.$store.commit(
          "accredited_institution_programmes/setRecords",
          value
        );
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters[
          "accredited_institution_programmes/currentRecord"
        ];
      },
      set: function (value) {
        this.$store.commit(
          "accredited_institution_programmes/setCurrentRecord",
          value
        );
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("accredited_institution_programmes", [
      "fetchRecords",
      "deleteRecord",
    ]),
    getActionMenuModel(data) {
      return [];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords,
        };
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
